import React from 'react';

const Nemo = () => {
  return (
    <div className="nemo-container" style={{
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      top: '30px',
      left: 0
    }}>
      <iframe
        src="/nemo/home.html"
        title="Nemo Home"
        width="100%"
        height="100%"
        frameBorder="0"
      />
    </div>
  );
};

export default Nemo;
